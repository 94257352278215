import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from 'components/Static/Home';
import Welcome from 'components/Static/Welcome';
import Workshops from 'components/Static/Workshops';
import Dances from 'components/Static/Dances';
import Fragrance from 'components/Static/Fragrance';
import Staff from 'components/Static/Staff';
import Contact from 'components/Static/Contact';
import Schedule from 'components/Static/Schedule';
import Registration from 'components/Registration';
import Placeholder from 'components/Placeholder';
import MaterialLayout from 'components/Layout/';
import Error from 'components/Error';
import PaymentExplanation from 'components/Static/PaymentExplanation';
import ScrollToAnchor from 'components/ScrollToAnchor';
import { OrderProvider } from 'components/OrderContext';
import ArchivedLayout from 'components/ArchivedLayout';
import config from 'config';
const { EMAIL_CONTACT } = config;

export default function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToAnchor />
        <MaterialLayout>
          <OrderProvider>
            <Routes>
              <Route exact path="/" element=<Placeholder /> />
              <Route path="archived" element={<ArchivedLayout />}>
                <Route index element={<Home />} />
                <Route path="welcome" element={<Welcome />} />
                <Route path="staff" element={<Staff />} />
                <Route path="workshops" element={<Workshops />} />
                <Route path="schedule" element={<Schedule />} />
                <Route path="dances" element={<Dances />} />
                <Route path="fragrance" element={<Fragrance />} />
                <Route path="contact" element={<Contact />} />
                <Route path="waystopay" element={<PaymentExplanation />} />
                <Route path="registration" element={<Registration />} />
              </Route>
              <Route exact path="/error-contact-support" element=<Error error={`Unexpected payment processing error. Please email ${EMAIL_CONTACT}`} /> />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </OrderProvider>
        </MaterialLayout>
      </Router>
    </HelmetProvider>
  );
}
