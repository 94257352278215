import { Typography, Box, } from '@mui/material';
import { StyledPaper } from 'components/Layout/SharedStyles';

export default function Home() {

  return (
    <StyledPaper extraStyles={{ maxWidth: 750 }} align="center">

      <Typography variant="h4" align='center' sx={{ mb: 2 }}>
        2025 ECD Ball<br />
        November 7, 8, 9
      </Typography>
      <Typography variant="h4" align='center' sx={{ mb: 8 }}>
        Oaks Park Dance Pavilion<br />
        Portland, Oregon
      </Typography>

      <Box mt={-5} mb={4}>
        <img src={process.env.PUBLIC_URL + '/ecdball/ecdball-logo.jpg'} alt="ball logo" style={{ width: "100%", height: "auto" }} />
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        2025 ECD Ball &mdash; November 7, 8, 9<br />
        Oaks Park Dance Pavilion, Portland, Oregon
      </Typography>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Caller: Laura Hatch<br />
        Musicians: Fine Companions<br />
        (Bill Tomczak, Betsy Branch, Erik Weberg, Lisa Scott)<br />
        Sound Engineer: John Oorthuys
      </Typography>

      <Typography variant="h6" sx={{ mb: 2 }}>
        <em>Registration will open Mid-July 2025</em>
      </Typography>

    </StyledPaper>
  );
}
