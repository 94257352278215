import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

function ArchivedLayout() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>2024 (archived)</title>
      </Helmet>

      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <Typography variant="h6">2024 (archived)</Typography>
      </Box>
      <Outlet />
    </>
  );
}

export default ArchivedLayout;